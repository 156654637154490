/** @jsx jsx */
import { jsx } from "theme-ui"
import { useState } from "react"
import Img from "gatsby-image"
import Modal from "react-modal"
import Obfuscate from "react-obfuscate"

import image from "../../images/person_placeholder.jpg"

import {
  FaLinkedin,
  FaTimes,
  FaPhone,
  FaEnvelope,
  FaInfoCircle,
} from "react-icons/fa"

const customStyles = {
  content: {
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    marginRight: "-50%",
    transform: "translate(-50%, -50%)",
  },
}

const Speakers = props => {
  const [modalIsOpen, setIsOpen] = useState()

  function openModal(id) {
    setIsOpen(id)
  }
  function closeModal() {
    setIsOpen()
  }

  return (
    <div>
      {props?.speakers?.cYAN?.speaker ? (
        <div sx={{ background: `rgba(255,165,0, .1)`, p: `25px 0` }}>
          <div id="speakers" className="grid-container gap-xl">
            <h2 sx={{ p: `15px 0` }}>Speakers</h2>

            <div className="grid-x grid-margin-y grid-margin-x members">
              {props?.speakers?.cYAN?.speaker?.map((s, index) => {
                return (
                  <div
                    key={index}
                    className="large-3 medium-6 small-12 cell"
                    sx={{ position: `relative` }}
                  >
                    {s.speakerMember?.map(e => {
                      return (
                        <div key={e?.id}>
                          <div
                            sx={{
                              cursor: `pointer`,
                              width: `250px`,
                              height: `300px`,
                              margin: `0 auto`,
                              position: `relative`,
                              svg: {
                                transition: `200ms`,
                              },
                              ":hover svg": {
                                transform: `scale(1.2)`,
                              },
                            }}
                            onClick={() => openModal(e?.id)}
                          >
                            {e.person.personImage?.localFile?.childImageSharp
                              .fluid ? (
                              <Img
                                fluid={
                                  e.person.personImage?.localFile
                                    ?.childImageSharp.fluid
                                }
                              />
                            ) : (
                              <img src={image} alt="placeholder" />
                            )}
                            <FaInfoCircle
                              size="25"
                              sx={{
                                position: `absolute`,
                                bottom: `5%`,
                                background: `#3a5d7f`,
                                borderRadius: `30px`,
                                left: `5%`,
                                color: `#fff`,
                                p: `1px`,
                              }}
                            />
                          </div>
                          <div sx={{ marginBottom: `25px` }}>
                            {e.person.personName ? (
                              <h4
                                onClick={() => openModal(e?.id)}
                                sx={{
                                  cursor: `pointer`,
                                  margin: `15px 0 0px 0`,
                                  fontSize: `20px`,
                                  fontWeight: `600`,
                                }}
                              >
                                {e?.person?.personName}
                              </h4>
                            ) : null}
                            {s.speakerEventTitle ? (
                              <p sx={{ color: `#faa23a`, marginBottom: `0px` }}>
                                {s.speakerEventTitle}
                              </p>
                            ) : null}
                            {e.person.company ? (
                              <p sx={{ color: `#3a5d7f`, marginBottom: `0px` }}>
                                {e.person.company}
                              </p>
                            ) : null}
                            {e.person.alternativeCompany ? (
                              <p
                                sx={{ color: `#3a5d7f`, marginBottom: `15px` }}
                              >
                                {e.person.alternativeCompany}
                              </p>
                            ) : null}
                          </div>
                        </div>
                      )
                    })}
                  </div>
                )
              })}
            </div>
            {props?.speakers?.cYAN?.speaker?.map((d, index) => {
              return (
                <div key={index}>
                  {d.speakerMember?.map(row => {
                    return (
                      <div key={row?.id}>
                        <Modal
                          isOpen={modalIsOpen === row?.id}
                          onRequestClose={closeModal}
                          closeTimeoutMS={500}
                          style={customStyles}
                          ariaHideApp={false}
                          contentLabel={row?.id}
                        >
                          {row?.person?.personName ? (
                            <h2
                              sx={{ textAlign: `center`, marginBottom: `10px` }}
                            >
                              {row?.person?.personName}
                            </h2>
                          ) : null}
                          {d?.speakerEventTitle ? (
                            <p
                              sx={{
                                color: `#faa23a`,
                                mb: `50px`,
                                textAlign: `center`,
                              }}
                            >
                              {d?.speakerEventTitle}
                            </p>
                          ) : (
                            <p sx={{ mb: `50px` }}></p>
                          )}
                          <FaTimes
                            onClick={closeModal}
                            style={{
                              position: `absolute`,
                              right: `15px`,
                              top: `15px`,
                              cursor: `pointer`,
                            }}
                          />
                          <div className="grid-container" onClick={closeModal}>
                            <div className="grid-x grid-margin-x grid-margin-y">
                              <div className="large-6 medium-6 small-12 popup cell">
                                <div sx={{ margin: `0 auto` }}>
                                  <div
                                    sx={{
                                      width: `300px`,
                                      height: `100%`,
                                      margin: `0 auto`,
                                      textAlign: `center`,
                                    }}
                                  >
                                    {row.person.personImage?.localFile
                                      ?.childImageSharp.fluid ? (
                                      <Img
                                        fluid={
                                          row.person.personImage?.localFile
                                            ?.childImageSharp.fluid
                                        }
                                      />
                                    ) : (
                                      <img src={image} />
                                    )}
                                  </div>
                                  <div className="gap-l"></div>
                                  <ul
                                    onClick="#"
                                    sx={{
                                      justifyContent: `center`,
                                      margin: `0`,
                                      display: `flex`,
                                      listStyle: `none`,
                                      gap: `20px`,
                                    }}
                                  >
                                    {row.person.linkedinUrl ? (
                                      <li sx={{ m: [`10px`, `5px`, `0`] }}>
                                        <a
                                          href={row?.person?.linkedinUrl}
                                          target="_blank"
                                          rel="noreferrer"
                                        >
                                          <FaLinkedin size={20} />
                                        </a>
                                      </li>
                                    ) : null}
                                    {row.person.phone ? (
                                      <li sx={{ m: [`10px`, `5px`, `0`] }}>
                                        <Obfuscate
                                          href={`tel:${row.person.phone}`}
                                        >
                                          <FaPhone size={20} />
                                        </Obfuscate>
                                      </li>
                                    ) : null}
                                    {row.person.mailAddress ? (
                                      <li sx={{ m: [`10px`, `5px`, `0`] }}>
                                        <Obfuscate
                                          target="__blank"
                                          href={`mailto:${row.person.mailAddress}`}
                                        >
                                          <FaEnvelope size={20} />
                                        </Obfuscate>
                                      </li>
                                    ) : null}
                                  </ul>
                                </div>
                              </div>
                              <div className="large-6 medium-6 small-12 cell">
                                {row.person.company ? (
                                  <div>
                                    <p sx={{ mb: `0px` }}>
                                      {row?.person?.jobTitle}
                                    </p>
                                    {row.person.alternativeJobTitle ? (
                                      <p sx={{ mb: `0px` }}>
                                        {row?.person?.alternativeJobTitle}
                                      </p>
                                    ) : null}
                                    <p sx={{ mb: `0px` }}>
                                      <b>{row?.person?.company}</b>
                                    </p>
                                    {row.person.alternativeCompany ? (
                                      <p sx={{ mb: `0px` }}>
                                        <b>{row?.person?.alternativeCompany}</b>
                                      </p>
                                    ) : null}
                                  </div>
                                ) : null}
                                <p></p>
                                {row.person.bio ? (
                                  <p>
                                    <div
                                      dangerouslySetInnerHTML={{
                                        __html: row?.person?.bio,
                                      }}
                                    ></div>
                                  </p>
                                ) : null}
                              </div>
                            </div>
                          </div>
                        </Modal>
                      </div>
                    )
                  })}
                </div>
              )
            })}
          </div>
        </div>
      ) : null}
    </div>
  )
}

export default Speakers
