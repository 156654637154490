/** @jsx jsx */
import { jsx } from "theme-ui"

import AccordionModule from "../Events/AccordionModule"
import AccordionModuleCollapsed from "../Events/AccordionModuleCollapsed"
import { FaClock, FaFileDownload, FaInfo } from "react-icons/fa"
import BiliBili from "../../images/bilibili.png"
import YouTube from "../../images/youtube.svg"

const Accordion = props => {
  return (
    <div id="schedule">
      {props.accordion.cYAN.eventSchedule ? (
        <div className="grid-container gap-xxl">
          <h2>Schedule</h2>
          {props.accordion.cYAN.eventSchedule.map((e, index) => {
            return (
              <div key={index}>
                {props.accordion.cYAN.eventSettings.collapseSchedule === null ||
                false ? (
                  <AccordionModule
                    title={e?.date}
                    collapsed={
                      props.accordion.cYAN?.eventSettings?.collapseSchedule
                    }
                  >
                    <div sx={{ mt: `0px`, mb: `15px` }}>
                      <div>
                        {e.timezone ? (
                          <p sx={{ marginBottom: `5px` }}>{e.timezone}</p>
                        ) : null}
                      </div>
                    </div>
                    {e.timezoneLink ? (
                      <a
                        href={e.timezoneLink}
                        target="__blank"
                        sx={{
                          color: `#3a5d7f`,
                        }}
                      >
                        <button
                          sx={{
                            marginBottom: `20px`,
                          }}
                        >
                          <FaClock
                            size={20}
                            sx={{
                              mb: `-4px`,
                            }}
                          />
                          &nbsp; Convert timezone
                        </button>
                      </a>
                    ) : null}

                    <div className="accordion-text">
                      <div>
                        {e?.talksInTimeslot?.map((presentation, index) => {
                          return (
                            <div
                              key={index}
                              className="grid-x grid-margin-x grid-margin-y"
                              sx={{ position: `relative` }}
                            >
                              <div className="large-3 medium-4 small-12 cell">
                                <div sx={{ fontSize: `20px` }}>
                                  <p sx={{ marginBottom: `0` }}>
                                    {presentation?.startTime} to{" "}
                                    {presentation?.endTime}
                                  </p>
                                </div>
                              </div>
                              <div className="large-9 medium-8 small-12 cell">
                                {presentation.presentationRepeater.map(
                                  (presentationrepeat, index) => {
                                    return (
                                      <div
                                        key={index}
                                        sx={{
                                          border: `1px solid rgba(0,0,0, .2)`,
                                          borderRadius: `10px`,
                                          p: `10px`,
                                          mb: `15px`,
                                        }}
                                      >
                                        {presentationrepeat.location ? (
                                          <div>
                                            {presentationrepeat.locationColor ? (
                                              <div
                                                sx={{
                                                  background: `${presentationrepeat.locationColor}`,
                                                  color: `#fff`,
                                                  position: `absolute`,
                                                  left: `15px`,
                                                  fontWeight: `600`,
                                                  textAlign: `center`,
                                                  width: `auto`,
                                                  mt: `30px`,
                                                  maxWidth: `20%`,
                                                  overflowWrap: `break-word`,
                                                  padding: `7px 25px`,
                                                  "@media (max-width: 768px)": {
                                                    position: `relative`,
                                                    mb: `25px`,
                                                    top: `0rem`,
                                                    left: `0px`,
                                                    width: `fit-content`,
                                                    maxWidth: `90%`,
                                                    textAlign: `center`,
                                                    overflowWrap: `break-word`,
                                                  },
                                                }}
                                              >
                                                {presentationrepeat.location}
                                              </div>
                                            ) : null}
                                          </div>
                                        ) : null}
                                        {presentationrepeat.sessionTitle ? (
                                          <div className="gap-l">
                                            <h3
                                              sx={{
                                                color: `#faa23a`,
                                                mb: `15px`,
                                                fontSize: `1.6rem`,
                                              }}
                                            >
                                              {presentationrepeat.sessionTitle}
                                            </h3>

                                            {presentationrepeat.sessionDescription ? (
                                              <div
                                                dangerouslySetInnerHTML={{
                                                  __html:
                                                    presentationrepeat?.sessionDescription,
                                                }}
                                              ></div>
                                            ) : null}
                                          </div>
                                        ) : null}
                                        {presentationrepeat.sessionModerator?.map(
                                          (mod, index) => {
                                            return (
                                              <div key={index}>
                                                {mod.person.personName ||
                                                mod.person.jobTitle ||
                                                mod.person.company ? (
                                                  <p
                                                    sx={{
                                                      mb: `0px`,
                                                    }}
                                                  >
                                                    <b>Session Moderator: </b>
                                                    {
                                                      mod?.person?.personName
                                                    }, {mod?.person?.jobTitle},{" "}
                                                    {mod?.person?.company}
                                                  </p>
                                                ) : null}
                                              </div>
                                            )
                                          }
                                        )}
                                        {presentationrepeat.sessionChair?.map(
                                          (chair, index) => {
                                            return (
                                              <div
                                                key={index}
                                                className="gap-xl"
                                              >
                                                {chair.person.personName ||
                                                chair.person.jobTitle ||
                                                chair.person.company ? (
                                                  <p>
                                                    <b>Session Chair: </b>
                                                    {
                                                      chair?.person?.personName
                                                    }, {chair?.person?.jobTitle}
                                                    , {chair?.person?.company}
                                                  </p>
                                                ) : null}
                                              </div>
                                            )
                                          }
                                        )}
                                        {presentationrepeat.sessionRepeater?.map(
                                          (sessionrepeat, index) => {
                                            return (
                                              <div key={index}>
                                                {sessionrepeat.scheduledPresentation.map(
                                                  presentationschedule => {
                                                    return (
                                                      <div
                                                        key={
                                                          presentationschedule?.id
                                                        }
                                                      >
                                                        <div className="gap-l">
                                                          <div className="schedule__title">
                                                            <h4>
                                                              {
                                                                presentationschedule?.title
                                                              }
                                                            </h4>
                                                            <h4>
                                                              {
                                                                presentationschedule
                                                                  ?.presentation
                                                                  ?.alternativeTitleChinese
                                                              }
                                                            </h4>
                                                          </div>
                                                          {presentationschedule
                                                            ?.presentation
                                                            ?.moderator ? (
                                                            <div
                                                              sx={{
                                                                mb: `15px`,
                                                              }}
                                                            >
                                                              {presentationschedule?.presentation?.moderator?.map(
                                                                theModerator => {
                                                                  return (
                                                                    <p
                                                                      key={
                                                                        theModerator.id
                                                                      }
                                                                      className="schedule__speaker"
                                                                    >
                                                                      <b>
                                                                        Moderator:
                                                                      </b>
                                                                      &nbsp;
                                                                      {
                                                                        theModerator
                                                                          ?.person
                                                                          ?.personName
                                                                      }
                                                                      ,{" "}
                                                                      {
                                                                        theModerator
                                                                          ?.person
                                                                          ?.jobTitle
                                                                      }
                                                                      ,{" "}
                                                                      {
                                                                        theModerator
                                                                          ?.person
                                                                          ?.company
                                                                      }
                                                                    </p>
                                                                  )
                                                                }
                                                              )}
                                                            </div>
                                                          ) : null}
                                                          {presentationschedule
                                                            ?.presentation
                                                            ?.presentationSpeaker ? (
                                                            <div className="gap-l">
                                                              {presentationschedule?.presentation?.presentationSpeaker?.map(
                                                                theSpeaker => {
                                                                  return (
                                                                    <div
                                                                      key={
                                                                        theSpeaker?.id
                                                                      }
                                                                      sx={{
                                                                        marginBottom: `5px`,
                                                                      }}
                                                                    >
                                                                      <p className="schedule__speaker">
                                                                        {
                                                                          theSpeaker
                                                                            ?.person
                                                                            ?.personName
                                                                        }
                                                                        ,{" "}
                                                                        {
                                                                          theSpeaker
                                                                            ?.person
                                                                            ?.jobTitle
                                                                        }
                                                                        ,{" "}
                                                                        {
                                                                          theSpeaker
                                                                            ?.person
                                                                            ?.company
                                                                        }
                                                                      </p>
                                                                    </div>
                                                                  )
                                                                }
                                                              )}
                                                            </div>
                                                          ) : null}
                                                          {presentationschedule
                                                            ?.presentation
                                                            ?.shortDescription ? (
                                                            <div>
                                                              <div
                                                                className="gap-l accordion-abstract"
                                                                dangerouslySetInnerHTML={{
                                                                  __html:
                                                                    presentationschedule
                                                                      ?.presentation
                                                                      ?.shortDescription,
                                                                }}
                                                              ></div>
                                                            </div>
                                                          ) : null}
                                                          {presentationschedule
                                                            ?.presentation
                                                            ?.shortDescriptionCn ? (
                                                            <div>
                                                              <div
                                                                className="gap-l accordion-abstract"
                                                                dangerouslySetInnerHTML={{
                                                                  __html:
                                                                    presentationschedule
                                                                      ?.presentation
                                                                      ?.shortDescriptionCn,
                                                                }}
                                                              ></div>
                                                            </div>
                                                          ) : null}
                                                          {presentationschedule
                                                            ?.presentation
                                                            ?.longDescription ? (
                                                            <details class>
                                                              <summary>
                                                                <b>
                                                                  Abstract
                                                                  (English)
                                                                </b>
                                                              </summary>
                                                              <div
                                                                className="gap-l accordion-abstract"
                                                                dangerouslySetInnerHTML={{
                                                                  __html:
                                                                    presentationschedule
                                                                      ?.presentation
                                                                      ?.longDescription,
                                                                }}
                                                              ></div>
                                                            </details>
                                                          ) : null}
                                                          {presentationschedule
                                                            ?.presentation
                                                            ?.longDescriptionCn ? (
                                                            <details className="gap-l">
                                                              <summary>
                                                                <b>
                                                                  摘要 (Chinese)
                                                                </b>
                                                              </summary>
                                                              <div
                                                                className="gap-l accordion-abstract"
                                                                dangerouslySetInnerHTML={{
                                                                  __html:
                                                                    presentationschedule
                                                                      ?.presentation
                                                                      ?.longDescriptionCn,
                                                                }}
                                                              ></div>
                                                            </details>
                                                          ) : null}
                                                          {presentationschedule
                                                            ?.presentation
                                                            ?.presentationUrl ? (
                                                            <div
                                                              sx={{
                                                                marginBottom: `5px`,
                                                              }}
                                                            >
                                                              <a
                                                                href={
                                                                  presentationschedule
                                                                    ?.presentation
                                                                    ?.presentationUrl
                                                                }
                                                                target="__blank"
                                                                rel="noreferrer"
                                                              >
                                                                <FaInfo
                                                                  size="18"
                                                                  color="#3a5d7f"
                                                                  sx={{
                                                                    marginRight: `10px`,
                                                                    mb: `-1px!important`,
                                                                  }}
                                                                />
                                                                More about this
                                                                presentation
                                                              </a>
                                                            </div>
                                                          ) : null}
                                                          {presentationschedule
                                                            ?.presentation
                                                            ?.files ? (
                                                            <ul
                                                              sx={{
                                                                listStyle: `none`,
                                                                margin: `0`,
                                                              }}
                                                            >
                                                              <li
                                                                sx={{
                                                                  paddingRight: `20px`,
                                                                }}
                                                              >
                                                                {presentationschedule?.presentation?.files?.map(
                                                                  (
                                                                    fileMap,
                                                                    index
                                                                  ) => {
                                                                    return (
                                                                      <div
                                                                        key={
                                                                          index
                                                                        }
                                                                      >
                                                                        {fileMap.file ? (
                                                                          <a
                                                                            href={
                                                                              fileMap
                                                                                .file
                                                                                .mediaItemUrl
                                                                            }
                                                                            target="__blank"
                                                                          >
                                                                            <FaFileDownload
                                                                              size="18"
                                                                              color="#3a5d7f"
                                                                              sx={{
                                                                                marginRight: `10px`,
                                                                                mb: `-3px!important`,
                                                                              }}
                                                                            />
                                                                            {
                                                                              fileMap
                                                                                .file
                                                                                .title
                                                                            }
                                                                          </a>
                                                                        ) : null}
                                                                      </div>
                                                                    )
                                                                  }
                                                                )}
                                                              </li>
                                                            </ul>
                                                          ) : null}
                                                          {presentationschedule
                                                            .presentation
                                                            .youtube ||
                                                          presentationschedule
                                                            .presentation
                                                            .bilibili ? (
                                                            <ul
                                                              sx={{
                                                                listStyle: `none`,
                                                                margin: `0`,
                                                                display: `flex`,
                                                              }}
                                                            >
                                                              <li
                                                                sx={{
                                                                  paddingRight: `20px`,
                                                                }}
                                                              >
                                                                {presentationschedule
                                                                  .presentation
                                                                  .youtube ? (
                                                                  <a
                                                                    href={
                                                                      presentationschedule
                                                                        ?.presentation
                                                                        ?.youtube
                                                                    }
                                                                    target="__blank"
                                                                  >
                                                                    <img
                                                                      src={
                                                                        YouTube
                                                                      }
                                                                      alt="YouTube"
                                                                      sx={{
                                                                        width: `25px`,
                                                                      }}
                                                                    ></img>
                                                                  </a>
                                                                ) : null}
                                                              </li>
                                                              <li>
                                                                {presentationschedule
                                                                  .presentation
                                                                  .bilibili ? (
                                                                  <a
                                                                    href={
                                                                      presentationschedule
                                                                        ?.presentation
                                                                        ?.bilibili
                                                                    }
                                                                    target="__blank"
                                                                  >
                                                                    <img
                                                                      src={
                                                                        BiliBili
                                                                      }
                                                                      alt="BiliBili"
                                                                      sx={{
                                                                        width: `65px`,
                                                                      }}
                                                                    ></img>
                                                                  </a>
                                                                ) : null}
                                                              </li>
                                                            </ul>
                                                          ) : null}
                                                        </div>
                                                      </div>
                                                    )
                                                  }
                                                )}
                                              </div>
                                            )
                                          }
                                        )}
                                      </div>
                                    )
                                  }
                                )}
                              </div>
                            </div>
                          )
                        })}
                      </div>
                    </div>
                  </AccordionModule>
                ) : (
                  <AccordionModuleCollapsed
                    title={e?.date}
                    collapsed={
                      props.accordion.cYAN?.eventSettings?.collapseSchedule
                    }
                  >
                    <div sx={{ mt: `0px`, mb: `15px` }}>
                      <div>
                        {e.timezone ? (
                          <p sx={{ marginBottom: `5px` }}>{e.timezone}</p>
                        ) : null}
                      </div>
                    </div>
                    {e.timezoneLink ? (
                      <a
                        href={e.timezoneLink}
                        target="__blank"
                        sx={{
                          color: `#3a5d7f`,
                        }}
                      >
                        <button
                          sx={{
                            marginBottom: `20px`,
                          }}
                        >
                          <FaClock
                            size={20}
                            sx={{
                              mb: `-4px`,
                            }}
                          />
                          &nbsp; Convert timezone
                        </button>
                      </a>
                    ) : null}

                    <div className="accordion-text">
                      <div>
                        {e?.talksInTimeslot?.map((presentation, index) => {
                          return (
                            <div
                              key={index}
                              className="grid-x grid-margin-x grid-margin-y"
                              sx={{ position: `relative` }}
                            >
                              <div className="large-3 medium-4 small-12 cell">
                                <div sx={{ fontSize: `20px` }}>
                                  <p sx={{ marginBottom: `0` }}>
                                    {presentation?.startTime} to{" "}
                                    {presentation?.endTime}
                                  </p>
                                </div>
                              </div>
                              <div className="large-9 medium-8 small-12 cell">
                                {presentation.presentationRepeater.map(
                                  (presentationrepeat, index) => {
                                    return (
                                      <div
                                        key={index}
                                        sx={{
                                          border: `1px solid rgba(0,0,0, .2)`,
                                          borderRadius: `10px`,
                                          p: `10px`,
                                          mb: `15px`,
                                        }}
                                      >
                                        {presentationrepeat.location ? (
                                          <div>
                                            {presentationrepeat.locationColor ? (
                                              <div
                                                sx={{
                                                  background: `${presentationrepeat.locationColor}`,
                                                  color: `#fff`,
                                                  position: `absolute`,
                                                  left: `15px`,
                                                  fontWeight: `600`,
                                                  textAlign: `center`,
                                                  width: `auto`,
                                                  mt: `30px`,
                                                  maxWidth: `20%`,
                                                  overflowWrap: `break-word`,
                                                  padding: `7px 25px`,
                                                  "@media (max-width: 768px)": {
                                                    position: `relative`,
                                                    mb: `25px`,
                                                    top: `0rem`,
                                                    left: `0px`,
                                                    width: `fit-content`,
                                                    maxWidth: `90%`,
                                                    textAlign: `center`,
                                                    overflowWrap: `break-word`,
                                                  },
                                                }}
                                              >
                                                {presentationrepeat.location}
                                              </div>
                                            ) : null}
                                          </div>
                                        ) : null}
                                        {presentationrepeat.sessionTitle ? (
                                          <div className="gap-l">
                                            <h3
                                              sx={{
                                                color: `#faa23a`,
                                                mb: `15px`,
                                                fontSize: `1.6rem`,
                                              }}
                                            >
                                              {presentationrepeat.sessionTitle}
                                            </h3>

                                            {presentationrepeat.sessionDescription ? (
                                              <div
                                                dangerouslySetInnerHTML={{
                                                  __html:
                                                    presentationrepeat?.sessionDescription,
                                                }}
                                              ></div>
                                            ) : null}
                                          </div>
                                        ) : null}
                                        {presentationrepeat.sessionModerator?.map(
                                          (mod, index) => {
                                            return (
                                              <div key={index}>
                                                {mod.person.personName ||
                                                mod.person.jobTitle ||
                                                mod.person.company ? (
                                                  <p
                                                    sx={{
                                                      mb: `0px`,
                                                    }}
                                                  >
                                                    <b>Session Moderator: </b>
                                                    {
                                                      mod?.person?.personName
                                                    }, {mod?.person?.jobTitle},{" "}
                                                    {mod?.person?.company}
                                                  </p>
                                                ) : null}
                                              </div>
                                            )
                                          }
                                        )}
                                        {presentationrepeat.sessionChair?.map(
                                          (chair, index) => {
                                            return (
                                              <div
                                                key={index}
                                                className="gap-xl"
                                              >
                                                {chair.person.personName ||
                                                chair.person.jobTitle ||
                                                chair.person.company ? (
                                                  <p>
                                                    <b>Session Chair: </b>
                                                    {
                                                      chair?.person?.personName
                                                    }, {chair?.person?.jobTitle}
                                                    , {chair?.person?.company}
                                                  </p>
                                                ) : null}
                                              </div>
                                            )
                                          }
                                        )}
                                        {presentationrepeat.sessionRepeater?.map(
                                          (sessionrepeat, index) => {
                                            return (
                                              <div key={index}>
                                                {sessionrepeat.scheduledPresentation.map(
                                                  presentationschedule => {
                                                    return (
                                                      <div
                                                        key={
                                                          presentationschedule?.id
                                                        }
                                                      >
                                                        <div className="gap-l">
                                                          <div className="schedule__title">
                                                            <h4>
                                                              {
                                                                presentationschedule?.title
                                                              }
                                                            </h4>
                                                            <h4>
                                                              {
                                                                presentationschedule
                                                                  ?.presentation
                                                                  ?.alternativeTitleChinese
                                                              }
                                                            </h4>
                                                          </div>
                                                          {presentationschedule
                                                            ?.presentation
                                                            ?.moderator ? (
                                                            <div
                                                              sx={{
                                                                mb: `15px`,
                                                              }}
                                                            >
                                                              {presentationschedule?.presentation?.moderator?.map(
                                                                theModerator => {
                                                                  return (
                                                                    <p
                                                                      key={
                                                                        theModerator.id
                                                                      }
                                                                      className="schedule__speaker"
                                                                    >
                                                                      <b>
                                                                        Moderator:
                                                                      </b>
                                                                      &nbsp;
                                                                      {
                                                                        theModerator
                                                                          ?.person
                                                                          ?.personName
                                                                      }
                                                                      ,{" "}
                                                                      {
                                                                        theModerator
                                                                          ?.person
                                                                          ?.jobTitle
                                                                      }
                                                                      ,{" "}
                                                                      {
                                                                        theModerator
                                                                          ?.person
                                                                          ?.company
                                                                      }
                                                                    </p>
                                                                  )
                                                                }
                                                              )}
                                                            </div>
                                                          ) : null}
                                                          {presentationschedule
                                                            ?.presentation
                                                            ?.presentationSpeaker ? (
                                                            <div className="gap-l">
                                                              {presentationschedule?.presentation?.presentationSpeaker?.map(
                                                                theSpeaker => {
                                                                  return (
                                                                    <div
                                                                      key={
                                                                        theSpeaker?.id
                                                                      }
                                                                      sx={{
                                                                        marginBottom: `5px`,
                                                                      }}
                                                                    >
                                                                      <p className="schedule__speaker">
                                                                        {
                                                                          theSpeaker
                                                                            ?.person
                                                                            ?.personName
                                                                        }
                                                                        ,{" "}
                                                                        {
                                                                          theSpeaker
                                                                            ?.person
                                                                            ?.jobTitle
                                                                        }
                                                                        ,{" "}
                                                                        {
                                                                          theSpeaker
                                                                            ?.person
                                                                            ?.company
                                                                        }
                                                                      </p>
                                                                    </div>
                                                                  )
                                                                }
                                                              )}
                                                            </div>
                                                          ) : null}
                                                          {presentationschedule
                                                            ?.presentation
                                                            ?.shortDescription ? (
                                                            <div>
                                                              <div
                                                                className="gap-l accordion-abstract"
                                                                dangerouslySetInnerHTML={{
                                                                  __html:
                                                                    presentationschedule
                                                                      ?.presentation
                                                                      ?.shortDescription,
                                                                }}
                                                              ></div>
                                                            </div>
                                                          ) : null}
                                                          {presentationschedule
                                                            ?.presentation
                                                            ?.shortDescriptionCn ? (
                                                            <div>
                                                              <div
                                                                className="gap-l accordion-abstract"
                                                                dangerouslySetInnerHTML={{
                                                                  __html:
                                                                    presentationschedule
                                                                      ?.presentation
                                                                      ?.shortDescriptionCn,
                                                                }}
                                                              ></div>
                                                            </div>
                                                          ) : null}
                                                          {presentationschedule
                                                            ?.presentation
                                                            ?.longDescription ? (
                                                            <details class>
                                                              <summary>
                                                                <b>
                                                                  Abstract
                                                                  (English)
                                                                </b>
                                                              </summary>
                                                              <div
                                                                className="gap-l accordion-abstract"
                                                                dangerouslySetInnerHTML={{
                                                                  __html:
                                                                    presentationschedule
                                                                      ?.presentation
                                                                      ?.longDescription,
                                                                }}
                                                              ></div>
                                                            </details>
                                                          ) : null}
                                                          {presentationschedule
                                                            ?.presentation
                                                            ?.longDescriptionCn ? (
                                                            <details className="gap-l">
                                                              <summary>
                                                                <b>
                                                                  摘要 (Chinese)
                                                                </b>
                                                              </summary>
                                                              <div
                                                                className="gap-l accordion-abstract"
                                                                dangerouslySetInnerHTML={{
                                                                  __html:
                                                                    presentationschedule
                                                                      ?.presentation
                                                                      ?.longDescriptionCn,
                                                                }}
                                                              ></div>
                                                            </details>
                                                          ) : null}
                                                          {presentationschedule
                                                            ?.presentation
                                                            ?.presentationUrl ? (
                                                            <div
                                                              sx={{
                                                                marginBottom: `5px`,
                                                              }}
                                                            >
                                                              <a
                                                                href={
                                                                  presentationschedule
                                                                    ?.presentation
                                                                    ?.presentationUrl
                                                                }
                                                                target="__blank"
                                                                rel="noreferrer"
                                                              >
                                                                <FaInfo
                                                                  size="18"
                                                                  color="#3a5d7f"
                                                                  sx={{
                                                                    marginRight: `10px`,
                                                                    mb: `-1px!important`,
                                                                  }}
                                                                />
                                                                More about this
                                                                presentation
                                                              </a>
                                                            </div>
                                                          ) : null}
                                                          {presentationschedule
                                                            ?.presentation
                                                            ?.files ? (
                                                            <ul
                                                              sx={{
                                                                listStyle: `none`,
                                                                margin: `0`,
                                                              }}
                                                            >
                                                              <li
                                                                sx={{
                                                                  paddingRight: `20px`,
                                                                }}
                                                              >
                                                                {presentationschedule?.presentation?.files?.map(
                                                                  (
                                                                    fileMap,
                                                                    index
                                                                  ) => {
                                                                    return (
                                                                      <div
                                                                        key={
                                                                          index
                                                                        }
                                                                      >
                                                                        {fileMap.file ? (
                                                                          <a
                                                                            href={
                                                                              fileMap
                                                                                .file
                                                                                .mediaItemUrl
                                                                            }
                                                                            target="__blank"
                                                                          >
                                                                            <FaFileDownload
                                                                              size="18"
                                                                              color="#3a5d7f"
                                                                              sx={{
                                                                                marginRight: `10px`,
                                                                                mb: `-3px!important`,
                                                                              }}
                                                                            />
                                                                            {
                                                                              fileMap
                                                                                .file
                                                                                .title
                                                                            }
                                                                          </a>
                                                                        ) : null}
                                                                      </div>
                                                                    )
                                                                  }
                                                                )}
                                                              </li>
                                                            </ul>
                                                          ) : null}
                                                          {presentationschedule
                                                            .presentation
                                                            .youtube ||
                                                          presentationschedule
                                                            .presentation
                                                            .bilibili ? (
                                                            <ul
                                                              sx={{
                                                                listStyle: `none`,
                                                                margin: `0`,
                                                                display: `flex`,
                                                              }}
                                                            >
                                                              <li
                                                                sx={{
                                                                  paddingRight: `20px`,
                                                                }}
                                                              >
                                                                {presentationschedule
                                                                  .presentation
                                                                  .youtube ? (
                                                                  <a
                                                                    href={
                                                                      presentationschedule
                                                                        ?.presentation
                                                                        ?.youtube
                                                                    }
                                                                    target="__blank"
                                                                  >
                                                                    <img
                                                                      src={
                                                                        YouTube
                                                                      }
                                                                      alt="YouTube"
                                                                      sx={{
                                                                        width: `25px`,
                                                                      }}
                                                                    ></img>
                                                                  </a>
                                                                ) : null}
                                                              </li>
                                                              <li>
                                                                {presentationschedule
                                                                  .presentation
                                                                  .bilibili ? (
                                                                  <a
                                                                    href={
                                                                      presentationschedule
                                                                        ?.presentation
                                                                        ?.bilibili
                                                                    }
                                                                    target="__blank"
                                                                  >
                                                                    <img
                                                                      src={
                                                                        BiliBili
                                                                      }
                                                                      alt="BiliBili"
                                                                      sx={{
                                                                        width: `65px`,
                                                                      }}
                                                                    ></img>
                                                                  </a>
                                                                ) : null}
                                                              </li>
                                                            </ul>
                                                          ) : null}
                                                        </div>
                                                      </div>
                                                    )
                                                  }
                                                )}
                                              </div>
                                            )
                                          }
                                        )}
                                      </div>
                                    )
                                  }
                                )}
                              </div>
                            </div>
                          )
                        })}
                      </div>
                    </div>
                  </AccordionModuleCollapsed>
                )}
              </div>
            )
          })}
          <p sx={{ mt: `5px`, ml: `5px` }}>
            Schedule subject to change without notice.
          </p>
        </div>
      ) : null}
    </div>
  )
}

export default Accordion
