import React from "react"
import { graphql } from "gatsby"
import Layout from "../components/layout"
import Img from "gatsby-image"
import SEO from "../components/seo"
import AnchorLink from "react-anchor-link-smooth-scroll"
import Committees from "../components/Events/committees"
import Speakers from "../components/Events/speakers"
import Accordion from "../components/Events/Accordion"
import HeroBanner from "../components/Events/HeroBanner"
import HeroSection from "../components/Events/HeroSection"
import FileUpdates from "../components/Events/FileUpdates"
import Sponsors from "../components/Events/sponsors"
import Posts from "../components/Events/posts"
import BgImageFooter from "../components/Events/FooterImage"
import Footer from "../components/footer"

const Event = props => {
  const seoTitle = props.data.wpEventPage.cYAN?.seoMetaTitle
  const seoDescription = props.data.wpEventPage.cYAN?.seoMetaDescription

  return (
    <Layout>
      <SEO
        title={seoTitle}
        defaultName={props.data.wpEventPage?.title}
        description={seoDescription}
      />
      <div>
        <HeroBanner hero={props.data.wpEventPage} />
        <HeroSection section={props.data.wpEventPage} />
        <div className="grid-container gap-xxxl">
          <div className="grid-x grid-margin-x grid-margin-y">
            {props.data.wpEventPage.cYAN?.eventSchedule ? (
              <div className="large-6 cell sum2020 high">
                <AnchorLink offset="120" href="#schedule">
                  {props.data.wpEventPage.cYAN.tiles?.schedule?.localFile
                    .childImageSharp.fluid ? (
                    <Img
                      fluid={
                        props.data.wpEventPage.cYAN.tiles.schedule.localFile
                          .childImageSharp.fluid
                      }
                    />
                  ) : (
                    <Img fluid={props.data.schedule.childImageSharp.fluid} />
                  )}
                  <div className="sum2020-program">
                    <h3 className="sum2020-program-headline">Schedule</h3>
                  </div>
                </AnchorLink>
              </div>
            ) : null}
            {props.data.wpEventPage.cYAN?.speaker ? (
              <div className="large-6 cell sum2020 high">
                <AnchorLink offset="120" href="#speakers">
                  {props.data.wpEventPage.cYAN.tiles?.speakers?.localFile
                    .childImageSharp.fluid ? (
                    <Img
                      fluid={
                        props.data.wpEventPage.cYAN.tiles?.speakers?.localFile
                          .childImageSharp.fluid
                      }
                    />
                  ) : (
                    <Img fluid={props.data.speakers.childImageSharp.fluid} />
                  )}
                  <div className="sum2020-program">
                    <h3 className="sum2020-program-headline">Speakers</h3>
                  </div>
                </AnchorLink>
              </div>
            ) : null}
            {props.data.wpEventPage.cYAN?.committee ? (
              <div className="large-6 cell sum2020 high">
                <AnchorLink offset="120" href="#committee">
                  {props.data.wpEventPage.cYAN.tiles?.committee?.localFile
                    .childImageSharp.fluid ? (
                    <Img
                      fluid={
                        props.data.wpEventPage.cYAN.tiles.committee.localFile
                          .childImageSharp.fluid
                      }
                    />
                  ) : (
                    <Img fluid={props.data.committee.childImageSharp.fluid} />
                  )}
                  <div className="sum2020-program">
                    <h3 className="sum2020-program-headline">Commitee</h3>
                  </div>
                </AnchorLink>
              </div>
            ) : null}
            {props.data.wpEventPage.cYAN?.file ? (
              <div className="large-6 cell sum2020 high">
                <AnchorLink offset="120" href="#files">
                  {props.data.wpEventPage.cYAN.tiles?.downloads?.localFile
                    .childImageSharp.fluid ? (
                    <Img
                      fluid={
                        props.data.wpEventPage.cYAN.tiles.downloads.localFile
                          .childImageSharp.fluid
                      }
                    />
                  ) : (
                    <Img fluid={props.data.downloads.childImageSharp.fluid} />
                  )}
                  <div className="sum2020-program">
                    <h3 className="sum2020-program-headline">Downloads</h3>
                  </div>
                </AnchorLink>
              </div>
            ) : null}
          </div>
        </div>
        <Posts posts={props.data.wpEventPage} />
        {props.data.wpEventPage.cYAN?.eventSettings?.sectionSettings?.includes(
          "schedule"
        ) ? (
          <Accordion accordion={props.data.wpEventPage} />
        ) : null}
        {props.data.wpEventPage.cYAN?.eventSettings?.sectionSettings?.includes(
          "committee"
        ) ? (
          <Committees committee={props.data.wpEventPage} />
        ) : null}
        {props.data.wpEventPage.cYAN?.eventSettings?.sectionSettings?.includes(
          "speaker"
        ) ? (
          <Speakers speakers={props.data.wpEventPage} />
        ) : null}
        <FileUpdates files={props.data.wpEventPage} />
        <Sponsors sponsors={props.data.wpEventPage} />
        {props.data.wpEventPage.cYAN?.footerImage?.localFile?.childImageSharp
          ?.fluid ? (
          <div className="grid-x bg-footer" style={{ height: `600px` }}>
            <div className="large-12">
              <BgImageFooter bgfooter={props.data.wpEventPage} />
            </div>
          </div>
        ) : null}
      </div>
      <Footer />
    </Layout>
  )
}
export const query = graphql`
  query($slug: String) {
    wpMenu(name: { eq: "Main Menu" }) {
      menuItems {
        nodes {
          label
          childItems {
            nodes {
              label
              url
            }
          }
        }
      }
    }
    allWpMenu {
      nodes {
        menuItems {
          nodes {
            childItems {
              nodes {
                label
                url
              }
            }
            label
            url
          }
        }
      }
    }
    wpEventPage(slug: { eq: $slug }) {
      date
      id
      title
      cYAN {
        seoMetaTitle
        seoMetaDescription
        eventSettings {
          eventOver
          collapseSchedule
          sectionSettings
        }
        heroImage {
          localFile {
            childImageSharp {
              fluid(maxWidth: 2000, quality: 100) {
                ...GatsbyImageSharpFluid_withWebp_noBase64
              }
            }
          }
        }
        heroHeadline
        heroText
        heroButtons {
          heroButton {
            title
            url
          }
        }
        eventDateRange
        introTitle
        introText
        venueName
        venueAddress
        venueBook {
          title
          url
        }
        contact {
          contactName
          contactUsImage {
            localFile {
              childImageSharp {
                fluid(maxWidth: 100, quality: 100) {
                  ...GatsbyImageSharpFluid_withWebp_noBase64
                }
              }
            }
          }
          contactPhone
          contactMail
        }
        descriptionLeft
        descriptionRight
        tiles {
          schedule {
            localFile {
              childImageSharp {
                fluid(maxWidth: 800, quality: 100) {
                  ...GatsbyImageSharpFluid_withWebp_noBase64
                }
              }
            }
          }
          committee {
            localFile {
              childImageSharp {
                fluid(maxWidth: 800, quality: 100) {
                  ...GatsbyImageSharpFluid_withWebp_noBase64
                }
              }
            }
          }
          speakers {
            localFile {
              childImageSharp {
                fluid(maxWidth: 800, quality: 100) {
                  ...GatsbyImageSharpFluid_withWebp_noBase64
                }
              }
            }
          }
          downloads {
            localFile {
              childImageSharp {
                fluid(maxWidth: 800, quality: 100) {
                  ...GatsbyImageSharpFluid_withWebp_noBase64
                }
              }
            }
          }
        }
        news {
          ... on WpPost {
            id
            excerpt
            postLink {
              postImage {
                localFile {
                  childImageSharp {
                    fluid(maxWidth: 800, quality: 100) {
                      ...GatsbyImageSharpFluid_withWebp_noBase64
                    }
                  }
                }
              }
              postContent
              postExcerpt
            }
            title
            categories {
              nodes {
                id
                name
              }
            }
            date(formatString: "MMMM DD, YYYY")
            content
            slug
          }
        }
        eventSchedule {
          date
          timezone
          timezoneLink
          talksInTimeslot {
            startTime
            endTime
            presentationRepeater {
              sessionTitle
              location
              locationColor
              sessionDescription
              sessionModerator {
                ... on WpPerson {
                  id
                  person {
                    personName
                    jobTitle
                    company
                    id
                  }
                }
              }
              sessionChair {
                ... on WpPerson {
                  id
                  person {
                    personName
                    jobTitle
                    company
                    id
                  }
                }
              }
              sessionRepeater {
                scheduledPresentation {
                  ... on WpPresentation {
                    id
                    title
                    presentation {
                      alternativeTitleChinese
                      moderator {
                        ... on WpPerson {
                          id
                          person {
                            personName
                            jobTitle
                            company
                          }
                        }
                      }
                      youtube
                      presentationUrl
                      bilibili
                      shortDescription
                      shortDescriptionCn
                      longDescription
                      longDescriptionCn
                      files {
                        file {
                          mediaItemUrl
                          title
                          localFile {
                            name
                            publicURL
                          }
                        }
                      }
                      presentationSpeaker {
                        ... on WpPerson {
                          id
                          title
                          person {
                            personName
                            jobTitle
                            company
                            phone
                            mailAddress
                            linkedinUrl
                            bio
                            id
                          }
                        }
                      }
                    }
                  }
                }
              }
            }
          }
        }
        speaker {
          speakerEventTitle
          speakerMember {
            ... on WpPerson {
              id
              person {
                personName
                personImage {
                  localFile {
                    childImageSharp {
                      fluid(maxWidth: 500, quality: 100) {
                        ...GatsbyImageSharpFluid_withWebp_noBase64
                      }
                    }
                  }
                }
                phone
                mailAddress
                linkedinUrl
                jobTitle
                alternativeJobTitle
                alternativeCompany
                id
                company
                bio
              }
            }
          }
        }
        committee {
          committeeEventTitle
          committeeMember {
            ... on WpPerson {
              id
              person {
                personName
                personImage {
                  localFile {
                    childImageSharp {
                      fluid(maxWidth: 500, quality: 100) {
                        ...GatsbyImageSharpFluid_withWebp_noBase64
                      }
                    }
                  }
                }
                phone
                mailAddress
                linkedinUrl
                jobTitle
                alternativeJobTitle
                alternativeCompany
                id
                company
                bio
              }
            }
          }
        }
        file {
          ... on WpFile {
            id
            title
            fileQuery {
              fileDescription
              file {
                localFile {
                  publicURL
                }
              }
            }
          }
        }
        footerImage {
          localFile {
            childImageSharp {
              fluid(maxWidth: 2000, quality: 100) {
                ...GatsbyImageSharpFluid_withWebp_noBase64
              }
            }
          }
        }
        sponsorLevelTitles {
          levelOne
          levelTwo
          levelThree
          levelFour
          levelFive
          levelSix
        }
        ranks {
          sponsor {
            ... on WpSponsor {
              id
              title
              sponsorDetails {
                sponsorLink
                sponsorDescription
                contactEmail
                upperImage {
                  localFile {
                    childImageSharp {
                      fluid(maxWidth: 1500, quality: 100) {
                        ...GatsbyImageSharpFluid_withWebp_noBase64
                      }
                    }
                  }
                }
                lowerImage {
                  localFile {
                    childImageSharp {
                      fluid(maxWidth: 1500, quality: 100) {
                        ...GatsbyImageSharpFluid_withWebp_noBase64
                      }
                    }
                  }
                }
                lowerMediaFileType
                lowerVideoCopy
                upperMediaFileType
                upperVideo
                contactEmail
                contactUrl
                emailButtonLabel
                urlButtonLabel
                contactInfo
                buttonsCheckbox
              }
              featuredImage {
                node {
                  localFile {
                    childImageSharp {
                      fluid(maxWidth: 1200, quality: 100) {
                        ...GatsbyImageSharpFluid_withWebp_noBase64
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
    allWpSponsor(sort: { fields: modified, order: DESC }) {
      nodes {
        featuredImage {
          node {
            localFile {
              childImageSharp {
                fluid(maxWidth: 1920, quality: 100) {
                  ...GatsbyImageSharpFluid_withWebp_noBase64
                }
              }
            }
          }
        }
        sponsorDetails {
          sponsorLink
          sponsorDescription
          contactEmail
          upperImage {
            localFile {
              childImageSharp {
                fluid(maxWidth: 1500, quality: 100) {
                  ...GatsbyImageSharpFluid_withWebp_noBase64
                }
              }
            }
          }
          lowerImage {
            localFile {
              childImageSharp {
                fluid(maxWidth: 1500, quality: 100) {
                  ...GatsbyImageSharpFluid_withWebp_noBase64
                }
              }
            }
          }
          lowerMediaFileType
          lowerVideoCopy
          upperMediaFileType
          upperVideo
          contactEmail
          contactUrl
          emailButtonLabel
          urlButtonLabel
          contactInfo
          buttonsCheckbox
        }
        id
        title
      }
    }
    schedule: file(relativePath: { eq: "schedule.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 800, quality: 100) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
        }
      }
    }
    speakers: file(relativePath: { eq: "summit.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 800, quality: 100) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
        }
      }
    }
    committee: file(relativePath: { eq: "commitee.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 800, quality: 100) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
        }
      }
    }
    downloads: file(relativePath: { eq: "file-updates.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 800, quality: 100) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
        }
      }
    }
  }
`
export default Event
