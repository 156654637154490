import React from "react"
import Img from "gatsby-image"
import Obfuscate from "react-obfuscate"

import {
  FaMapMarkerAlt,
  FaArrowRight,
  FaPhone,
  FaEnvelope,
  FaUser,
} from "react-icons/fa"

const HeroSection = props => {
  return (
    <div className="grid-container gap-xxl">
      <div className="grid-x grid-margin-x grid-margin-y">
        <div className="large-6 medium-6 cell">
          <span className="event-date-range">
            {props.section.cYAN.eventDateRange}
          </span>
          <h1>{props.section.cYAN.introTitle}</h1>
          <div
            dangerouslySetInnerHTML={{
              __html: props.section.cYAN.introText,
            }}
          ></div>
        </div>
        <div className="large-6 medium-6 cell gap-xl">
          <div>
            {props.section.cYAN.venueName ? (
              <div>
                <h3 style={{ color: `#faa23a`, fontWeight: `bold` }}>Venue</h3>
                <div className="event-venue">
                  <FaMapMarkerAlt
                    className="event-venue__marker"
                    size="55"
                    color="#3a5d7f"
                  />
                  <div>
                    <h4>{props.section.cYAN.venueName}</h4>
                    {props.section.cYAN?.venueAddress ? (
                      <div>
                        <p>{props.section.cYAN.venueAddress}</p>
                        <div>
                          {props.section.cYAN.venueBook ? (
                            <div>
                              <a
                                style={{
                                  marginRight: `20px`,
                                  color: `#3a5d7f`,
                                }}
                                href={props.section.cYAN.venueBook.url}
                                target="_blank"
                                rel="noreferrer"
                              >
                                <FaArrowRight
                                  size="13"
                                  style={{
                                    marginRight: `7px`,
                                    color: `#3a5d7f`,
                                  }}
                                />
                                {props.section.cYAN.venueBook.title}
                              </a>
                            </div>
                          ) : null}
                        </div>
                      </div>
                    ) : null}
                  </div>
                </div>
              </div>
            ) : null}
          </div>
          <h3 style={{ color: `#faa23a`, fontWeight: `bold` }}>Contact us</h3>
          <div>
            {props.section.cYAN.contact.map((e, index) => {
              return (
                <div key={index} className="event-contact">
                  {e?.contactUsImage?.localFile.childImageSharp.fluid ? (
                    <Img
                      className="bcooper"
                      fluid={e.contactUsImage.localFile.childImageSharp.fluid}
                    />
                  ) : (
                    <FaUser
                      className="event-venue__marker"
                      size="45"
                      color="#3a5d7f"
                    />
                  )}
                  <div className="event-contact__info">
                    <h4>{e?.contactName}</h4>
                    <div className="grid-x">
                      <div>
                        <FaPhone
                          size="13"
                          style={{ marginRight: `7px`, color: `#3a5d7f` }}
                        />
                      </div>
                      <Obfuscate
                        style={{ marginRight: `20px`, color: `#3a5d7f` }}
                        href={`tel:${e.contactPhone}`}
                      >
                        Phone
                      </Obfuscate>
                      <div>
                        <FaEnvelope
                          size="13"
                          style={{ marginRight: `7px`, color: `#3a5d7f` }}
                        />
                      </div>
                      <Obfuscate
                        style={{ marginRight: `20px`, color: `#3a5d7f` }}
                        href={`mailto:${e.contactMail}`}
                        target="__blank"
                      >
                        Mail
                      </Obfuscate>
                    </div>
                  </div>
                </div>
              )
            })}
          </div>
        </div>
        <div id="description">
          <div className="large-6 cell">
            <div
              dangerouslySetInnerHTML={{
                __html: props?.section?.cYAN?.descriptionLeft,
              }}
            ></div>
          </div>
          <div className="large-6 cell">
            <div
              dangerouslySetInnerHTML={{
                __html: props?.section?.cYAN?.descriptionRight,
              }}
            ></div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default HeroSection
