import React from "react"
import { Link } from "gatsby"
import Img from "gatsby-image"

const Posts = props => {
  return (
    <div id="news">
      {props.posts?.cYAN?.news ? (
        <div className="grid-container gap-xl posts">
          <h2>News</h2>
          <div className="grid-x grid-margin-x grid-margin-y">
            {props.posts?.cYAN?.news?.map(e => (
              <div key={e?.id} className="large-6 medium-12 small-12 cell">
                <div className="postsEvent__img_holder">
                  <Link to={`/news/${e?.slug}`}>
                    <Img
                      fluid={
                        e?.postLink?.postImage?.localFile?.childImageSharp
                          ?.fluid
                      }
                      alt={e?.title}
                    />
                  </Link>
                  <Link to={`/news/${e?.slug}`}>
                    <span className="news-button">Read more</span>
                  </Link>
                </div>
                <span className="news-category">{e?.date}</span>
                <h3>
                  <Link to={`/news/${e?.slug}`}>{e?.title}</Link>
                </h3>
                <div
                  dangerouslySetInnerHTML={{
                    __html: e?.postLink?.postExcerpt,
                  }}
                ></div>{" "}
              </div>
            ))}
          </div>
        </div>
      ) : null}
    </div>
  )
}

export default Posts
