import React, { useState, useEffect } from "react"
import Img from "gatsby-image"

const HeroBanner = props => {
  const [button, setButton] = useState(false)
  useEffect(() => {
    const filterButton = () => {
      if (props.hero.cYAN.heroButtons === null) {
        return []
      } else if (props.hero.cYAN.heroButtons.length >= 0) {
        setButton(true)
      }
    }
    filterButton()
  }, [])

  return (
    <div className="hero-event gap-xl">
      <div className="hero-event-section">
        {props.hero.cYAN.heroImage?.localFile?.childImageSharp.fluid ? (
          <Img
            fluid={props.hero.cYAN.heroImage?.localFile?.childImageSharp.fluid}
            alt="conference"
          />
        ) : (
          <div style={{ paddingTop: `15rem` }}></div>
        )}
        <div className="hero-event-section-banner">
          <div className="grid-container hero-event-content">
            {props.hero?.cYAN?.heroHeadline ? (
              <h2>{props.hero?.cYAN?.heroHeadline}</h2>
            ) : null}
            {props.hero?.cYAN?.heroText ? (
              <div
                dangerouslySetInnerHTML={{
                  __html: props.hero?.cYAN?.heroText,
                }}
              ></div>
            ) : null}
            <div>
              {props.hero.cYAN.heroButtons?.map((e, index) => {
                return (
                  <button
                    key={index}
                    className="hero-event-section-buttons"
                    aria-label="Button"
                  >
                    <a href={e.heroButton?.url}>{e.heroButton?.title}</a>
                  </button>
                )
              })}
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default HeroBanner
