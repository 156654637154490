/** @jsx jsx */
import { jsx } from "theme-ui"
import { Link } from "gatsby"

import { BsFileEarmarkText } from "react-icons/bs"

function bytesToSize(bytes, seperator = "") {
  const sizes = ["Bytes", "KB", "MB", "GB", "TB"]
  if (bytes === 0) return "n/a"
  const i = parseInt(Math.floor(Math.log(bytes) / Math.log(1024)), 10)
  if (i === 0) return `${bytes}${seperator}${sizes[i]}`
  return `${(bytes / 1024 ** i).toFixed(1)}${seperator}${sizes[i]}`
}

const FileUpdates = props => {
  return (
    <div>
      {props.files.cYAN.file ? (
        <div
          sx={{ background: `#f5f6f9`, padding: `50px 0` }}
          className="gap-xxl"
        >
          <div id="files" className="grid-container">
            <h2 sx={{ marginBottom: `1.45rem` }}>Downloads</h2>
            <div
              className="grid-x grid-margin-x grid-margin-y gap-l"
              sx={{ marginInlineStart: `auto` }}
            >
              {props.files.cYAN.file ? (
                props.files?.cYAN?.file?.map(e => (
                  <div key={e.id} className="large-6 cell">
                    <a
                      href={e?.fileQuery?.file?.localFile?.publicURL}
                      target="_blank"
                      rel="noreferrer"
                    >
                      <div className="grid-x file">
                        <div className="large-2">
                          <BsFileEarmarkText size={45} color={`#3a5d7f`} />
                        </div>
                        <div className="large-10">
                          <h4 className="file-title">
                            {e.title}
                            {/*<span className="file-size"> {e.file.fileSize}</span>*/}
                          </h4>
                          <div
                            className="file-text"
                            dangerouslySetInnerHTML={{
                              __html: e?.fileQuery?.fileDescription,
                            }}
                          ></div>
                        </div>
                      </div>
                    </a>
                  </div>
                ))
              ) : (
                <p>Currently there are no downloads.</p>
              )}
            </div>
            <Link to="/files">
              <button className="float-center" sx={{ width: `auto` }}>
                All Downloads
              </button>
            </Link>
          </div>
        </div>
      ) : null}
    </div>
  )
}

export default FileUpdates
